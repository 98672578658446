<ion-app>
  <ion-tabs>

    <ion-tab-bar slot="bottom">
      <ion-tab-button tab="inscripción">
        <ion-icon name="reader-outline"></ion-icon>
        <ion-label>Inscripción</ion-label>
      </ion-tab-button>
  
      <ion-tab-button tab="busqueda">
        <ion-icon name="search-outline"></ion-icon>
        <ion-label>Busqueda</ion-label>
      </ion-tab-button>
      
    </ion-tab-bar>
  
  </ion-tabs>
  
</ion-app>
